import {
  createListenerMiddleware,
  isAnyOf,
  type TypedStartListening,
} from '@reduxjs/toolkit'

import { RootState, AppDispatch } from 'app/AppStore'

import { ordersApi } from './ordersApi'

import { accountsSlice } from 'entities/accounts/model/slice'
import { pspApi } from 'entities/psp/api/pspApi'
import { symbolsApi } from 'entities/symbols/api/symbolsApi'
import { ordersSlice } from '../model/slice'
import { accountsApi } from 'entities/accounts/api/accountsApi'

export const ordersListener = createListenerMiddleware()

export type TypedListening = TypedStartListening<RootState, AppDispatch>

export const startListenerOrders =
  ordersListener.startListening as TypedListening

startListenerOrders({
  matcher: isAnyOf(accountsSlice.actions.setCurrentAccount),
  effect: async (action, api) => {
    if (!!action?.payload?.id) {
      await api.dispatch(
        ordersApi.endpoints.orders.initiate(action.payload.id, {
          subscribe: false,
          forceRefetch: true,
        }),
      )

      api.dispatch(
        ordersApi.endpoints.ordersHistory.initiate(action.payload.id, {
          subscribe: false,
          forceRefetch: true,
        }),
      )

      const state = api.getState()

      api.dispatch(
        pspApi.endpoints.psp.initiate({
          brand_id: state.session.me.brand_id,
          type: 1,
        }),
        {
          subscribe: false,
          forceRefetch: true,
        },
      )

      api.dispatch(
        symbolsApi.endpoints.symbols.initiate(state?.accounts.current.group.id),
        {
          subscribe: false,
          forceRefetch: true,
        },
      )
    }
  },
})

startListenerOrders({
  matcher: isAnyOf(
    ordersSlice.actions.setOrder,
    ordersSlice.actions.setNewOrder,
    accountsSlice.actions.setTransaction,
  ),
  effect: async (action, api) => {
    try {
      const state = api.getState()

      const accId = state.accounts?.current?.id

      if (accId) {
        await api
          .dispatch(
            accountsApi.endpoints.account.initiate(accId, {
              subscribe: false,
              forceRefetch: true,
            }),
          )
          .unwrap()
        await api.dispatch(
          accountsApi.endpoints.accountTransaction.initiate(accId, {
            subscribe: false,
            forceRefetch: true,
          }),
        )
        await api.dispatch(
          accountsApi.endpoints.accounts.initiate(accId, {
            subscribe: false,
            forceRefetch: true,
          }),
        )
      }
    } catch (error) {
      console.error('Failed to fetch account data:', error)
    }
  },
})
