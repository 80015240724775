import { baseApi } from 'shared/api'
import {
  SYMBOL_CHANEL,
  CREATE_TRANSACTION,
  UPD_ORDER,
  NEW_ORDER,
  SEND_MESSAGE,
  UPD_TRANSACTION,
} from './constants'
import { io } from 'socket.io-client'
import { transcformArrayToObject } from 'entities/symbols/helpers/helpers'
import { setSymbolQuote } from 'entities/symbols/model/slice'
import { customNotify } from 'shared/ui/CustomNotify/customNotify'
import { setMessage } from 'entities/support/model/slice'
import { setOrder, setNewOrder } from 'entities/orders/model/slice'
import { setTransaction } from 'entities/accounts/model/slice'

const SOCKET_HOST = process.env.REACT_APP_WS_URL
const CRM_SOCKET_HOST = process.env.REACT_APP_CRM_WS_URL

export const wsApi = baseApi.injectEndpoints({
  endpoints: build => ({
    subscribeToEvents: build.query<any, void>({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(_arg, { dispatch, cacheEntryRemoved, getState }) {
        const token = getState()?.session?.accessToken
        const uid = getState()?.session?.me?.id

        const socket = io(SOCKET_HOST, {
          transports: ['websocket'],
          auth: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        })

        const crmSocket = io(CRM_SOCKET_HOST, {
          transports: ['websocket'],
          auth: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        })

        let lastExecutionTime = 0

        socket.on(SYMBOL_CHANEL, data => {
          const currentTime = Date.now()

          if (currentTime - lastExecutionTime >= 5000) {
            const transformed = transcformArrayToObject(data)

            dispatch(setSymbolQuote(transformed))

            // Object.entries(transformed).map(([symbol, quote]) =>
            //   dispatch(setSymbolQuote({ symbol, quote })),
            // )
            // lastExecutionTime = currentTime
          }
        })

        crmSocket.on(SEND_MESSAGE, event => {
          customNotify(event.data.message, 'message')
          dispatch(setMessage(event.data))
        })

        crmSocket.on(CREATE_TRANSACTION, event => {
          customNotify(event.message, 'deposit')
          dispatch(setTransaction())
        })

        crmSocket.on(UPD_TRANSACTION, event => {
          customNotify(event.message, 'deposit')
          dispatch(setTransaction())
        })

        crmSocket.on(NEW_ORDER, event => {
          dispatch(setNewOrder(event.data))
        })

        crmSocket.on(UPD_ORDER, event => {
          dispatch(setOrder(event.data))
        })

        await cacheEntryRemoved
        socket.close()
      },
    }),
  }),
})

export const { useSubscribeToEventsQuery } = wsApi
