import { setChatMeId } from 'entities/session/model/slice'
import { baseApi } from 'shared/api'

export const supportApi = baseApi.injectEndpoints({
  endpoints: build => ({
    sendMessage: build.mutation<any, any>({
      query: body => ({
        url: `chat/cfd/message/create`,
        method: 'POST',
        body,
      }),
    }),
    createChat: build.mutation<any, any>({
      query: () => ({
        url: `chat/cfd/create`,
        method: 'POST',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled

        dispatch(setChatMeId(data.data.id))
      },
    }),
    messages: build.query<any, string>({
      query: chat_id => ({
        url: `chat/cfd/message/list?chat_id=${chat_id}`,
        method: 'GET',
      }),
    }),
    changeStatus: build.mutation<any, string>({
      query: body => ({
        url: `/chat/message/change-status`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const {
  useSendMessageMutation,
  useMessagesQuery,
  useCreateChatMutation,
  useChangeStatusMutation,
} = supportApi
