import { ORDER_VOLUME_TYPE } from 'entities/orders/lib/constants'

export const calculateMargin = (orders, leverage, symbols) => {
  let totalOrderValue = 0

  orders.forEach(order => {
    const { volume, open_price, volume_type, symbol } = order

    const orderSymbolType = symbols?.[symbol]?.type

    const leverageValue = leverage?.[orderSymbolType]?.leverage_value || 1
    const lotSize = leverage?.[orderSymbolType]?.lot_size
    let orderVolume = volume

    if (volume_type === ORDER_VOLUME_TYPE.CURRENCY) {
      orderVolume = volume / open_price
    }
    if (
      volume_type === ORDER_VOLUME_TYPE.UNIT ||
      volume_type === ORDER_VOLUME_TYPE.LOTS
    ) {
      const volumeInUnits = volume * lotSize
      orderVolume = (volumeInUnits / leverageValue) * open_price
    }

    totalOrderValue += orderVolume
  })

  return totalOrderValue
}
