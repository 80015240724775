import { baseApi } from 'shared/api'
import { AutotraderData } from './types'

export const autotraderApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getAutotrader: build.query<AutotraderData, string>({
      query: leadId => ({
        url: `/autotrader/cfd/get?lead_id=${leadId}`,
        method: 'GET',
      }),
    }),
    createAutotrader: build.mutation<AutotraderData, Partial<AutotraderData>>({
      query: data => ({
        url: '/autotrader/cfd/create',
        method: 'POST',
        body: data,
      }),
    }),
    updateAutotrader: build.mutation<
      AutotraderData,
      { leadId: string; data: Partial<AutotraderData> }
    >({
      query: ({ leadId, data }) => ({
        url: `/autotrader/cfd/update?lead_id=${leadId}`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteAutotrader: build.mutation<void, string>({
      query: leadId => ({
        url: `/autotrader/cfd/delete?lead_id=${leadId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetAutotraderQuery,
  useCreateAutotraderMutation,
  useUpdateAutotraderMutation,
  useDeleteAutotraderMutation,
} = autotraderApi
