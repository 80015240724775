import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Transfer.module.sass'
import { Icon } from '../Icon/Icon'
import { TextInput } from '../TextInput/TextInput'
import { Dropdown } from '../Dropdown/Dropdown'
import { priceToView } from 'shared/utils/format/currency'

export const Transfer = ({ account, accounts, onCreate }) => {
  const [state, setState] = useState({
    from: accounts[0].label,
    to: accounts[1].label,
    amount: 0,
  })

  const handleClickCreate = () => {
    onCreate({
      ...state,
      from: accounts.find(item => item.label === state.from).value,
      to: accounts.find(item => item.label === state.to).value,
    })
  }

  return (
    <div className={styles.transfer}>
      <div className={cn('h4', styles.title)}>
        <Icon name='arrow-left' size='32' />
        Transfer
      </div>
      {account && (
        <div className={styles.wrap}>
          <div className={styles.category}>
            Available <br></br>balance
          </div>
          <div className={styles.details}>
            <div className={styles.currency}>
              {priceToView(account?.balance, account?.currency)}
            </div>
            {/* <div className={styles.price}>$10,095.35</div> */}
          </div>
        </div>
      )}

      <div className={styles.field}>
        <Dropdown
          className={styles.dropdown}
          label='from'
          value={state.from}
          setValue={value => setState(prev => ({ ...prev, from: value }))}
          options={accounts.map(item => item.label)}
        />
      </div>
      {/* <div className={styles.sign}>
        <Icon name='arrows' size='16' />
      </div> */}
      <div className={styles.field}>
        <Dropdown
          className={styles.dropdown}
          label='to'
          value={state.to}
          setValue={value => setState(prev => ({ ...prev, to: value }))}
          options={accounts.map(item => item.label)}
        />
      </div>
      {account && (
        <div className={styles.field}>
          <Dropdown
            className={styles.dropdown}
            label='Currency'
            value={account?.currency}
            setValue={value => setState(prev => ({ ...prev, currency: value }))}
            options={[account?.currency]}
          />
        </div>
      )}

      <div className={styles.box}>
        <TextInput
          className={styles.field}
          label='Amount to transfer'
          name='amount'
          type='number'
          note={
            account
              ? `${priceToView(account?.balance, account?.currency)} available`
              : ''
          }
          onChange={e =>
            setState(prev => ({ ...prev, amount: e.target.value }))
          }
          required
        />
        <button className={cn('button-stroke button-small', styles.button)}>
          Max amount
        </button>
      </div>
      <button
        onClick={handleClickCreate}
        className={cn('button', styles.button)}
      >
        Transfer
      </button>
    </div>
  )
}
