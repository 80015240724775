import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from 'app/AppStore'

type sliceState = {
  currency: string
  language: string
}

enum LSName {
  currency = 'currency',
  language = 'language',
}

const initialState: sliceState = {
  currency: (localStorage.getItem(LSName.currency) as string) || 'USD',
  language: (localStorage.getItem(LSName.language) as string) || 'EN',
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    changeCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload
      localStorage.setItem(LSName.currency, action.payload)
    },
    changeLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
      localStorage.setItem(LSName.language, action.payload)
    },
  },
})

export const selectCurrency = (state: RootState) => state.settings.currency
export const selectLanguage = (state: RootState) => state.settings.language

export const { changeCurrency, changeLanguage } = settingsSlice.actions
