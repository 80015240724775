import { TransactionData } from '../api/types'

export const mapTransactions = ({
  data,
}: {
  data: TransactionData[]
  code: number
  message: string
  meta: object
  status: boolean
}): TransactionData[] => {
  return data
}
