import React from 'react'
import cn from 'classnames'
import styles from './Successfully.module.sass'
import { Link } from 'react-router-dom'
import { priceToView } from 'shared/utils/format/currency'

export const Successfully = ({ state, accountUid }) => {
  const items = [
    {
      title: 'Status',
      content: 'Pending',
      color: '#58BD7D',
    },
    {
      title: 'Transaction ID',
      content: '0msx836930...87r398',
    },
    {
      title: 'Info',
      content: state.info,
    },
  ]
  return (
    <>
      <div className={styles.successfully}>
        <div className={cn('h2', styles.title)}>
          Yay!{' '}
          <span role='img' aria-label='firework'>
            🎉
          </span>
        </div>
        <div className={styles.info}>
          You successfully a withdrawal{' '}
          <span>{priceToView(state.amount, state.currency)}</span> from{' '}
          {`#${accountUid}`}
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content} style={{ color: x.color }}>
                {x.content}
              </div>
            </div>
          ))}
        </div>
        <Link className={cn('button', styles.button)} to='/wallet-overview'>
          Wallet
        </Link>
      </div>
    </>
  )
}
