import { createSlice, createSelector } from '@reduxjs/toolkit'

import { RootState } from 'app/AppStore'

import { pspApi } from '../api/pspApi'
import { IOrdersData } from '../types'

type ordersSliceState = {
  list: IOrdersData[]
}

const initialState: ordersSliceState = {
  list: [],
}

export const pspSlice = createSlice({
  name: 'psp',
  initialState,
  reducers: {
    clearData: state => {
      state.list = []
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      pspApi.endpoints.psp.matchFulfilled,
      (state: ordersSliceState, { payload }) => {
        state.list = payload
      },
    )
  },
})

export const pspList = (state: RootState) => state.psp.list

export const selectPspList = createSelector([pspList], psp => psp)

export const { clearData } = pspSlice.actions
