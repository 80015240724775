import { baseApi } from 'shared/api'
import { pspDTO } from '../lib/pspDTO'

export const pspApi = baseApi.injectEndpoints({
  endpoints: build => ({
    psp: build.query<any, void>({
      query: arg => ({
        url: `psp/cfd/list?brand_id=${arg.brand_id}&type=${arg.type}`,
      }),

      transformResponse: pspDTO,
    }),
    otherPsp: build.query<any, void>({
      query: arg => ({
        url: `psp/cfd/list?brand_id=${arg.brand_id}&type=${2}`,
      }),

      transformResponse: pspDTO,
    }),
  }),
})

export const { usePspQuery, useOtherPspQuery } = pspApi
