import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { sessionApi } from '../api/sessionApi'

import { RootState } from 'app/AppStore'

import { isFetchBaseQueryError } from 'shared/api'

import { toast } from 'react-toastify'
import { User } from './types'

type Params = {
  token: string
}

export const autologinThunk = createAsyncThunk<
  void,
  Params,
  { state: RootState }
>('authentication/autologin', async (body: Params, { dispatch }) => {
  try {
    if (!body?.token) return
    await dispatch(
      sessionApi.endpoints.auto_login.initiate(body.token),
    ).unwrap()
  } catch (error) {
    if (isFetchBaseQueryError(error)) {
      if (typeof error.data === 'string') {
        throw new Error(error.data)
      }
    }

    throw new Error('Unknown error')
  }
})

type chartSliceState =
  | {
      currentChart: string
      charts: object
    }
  | {
      currentChart: null
      charts: object
    }

const initialState: chartSliceState = {
  currentChart: '',
  charts: {},
}

export const chartsSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    clearChartData: state => {
      state.currentChart = ''
      state.charts = {}
    },
  },
  extraReducers: builder => {},
})

export const selectCurrentChart = (state: RootState) => state.session.me

export const { clearChartData } = chartsSlice.actions
