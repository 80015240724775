import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/app.sass'
import { App } from './app/App'
import reportWebVitals from './reportWebVitals'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

reportWebVitals()
