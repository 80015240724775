import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { rootReducer } from './rootReducer'
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux'
import { sessionSlice, sessionApi } from 'entities/session'
import { chartsSlice } from 'entities/charts/model/slice'
import { baseApi } from 'shared/api'
import { invalidateAccessTokenListener } from 'features/auth/invalidateAccessToken'
import { loginSuccessListener } from 'entities/session/api/sessionMiddleware'
import { ordersListener } from 'entities/orders/api/ordersMeddlewaer'
import { getQueryParams } from 'shared/utils/browser/getQueryParams'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [sessionSlice.name, chartsSlice.name],
}

export function makeStore() {
  const store = configureStore({
    reducer: persistReducer(
      persistConfig,
      rootReducer,
    ) as unknown as typeof rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .concat(baseApi.middleware, invalidateAccessTokenListener.middleware)
        .prepend(loginSuccessListener.middleware, ordersListener.middleware),
  })

  setupListeners(store.dispatch)

  return store
}

export const appStore = makeStore()
export const persistedStore = persistStore(appStore, null, () => {
  const token = appStore.getState().session.accessToken
  if (token) {
    appStore.dispatch(sessionApi.endpoints.me.initiate())
  }
})

export type RootState = ReturnType<typeof appStore.getState>
export type AppDispatch = typeof appStore.dispatch

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useAppDispatch = useDispatch<AppDispatch>
