import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { accountsApi } from '../api/accountsApi'

import { RootState } from 'app/AppStore'

import { isFetchBaseQueryError } from 'shared/api'

import { toast } from 'react-toastify'

import { symbols } from 'mock/symbols'
import { groupedSymbols } from '../helpers/helpers'
import { AccountData } from '../api/types'
import { priceToView } from 'shared/utils/format/currency'

type Params = {
  token: string
}

// export const autologinThunk = createAsyncThunk<
//   void,
//   Params,
//   { state: RootState }
// >('authentication/autologin', async (body: Params, { dispatch }) => {
//   try {
//     if (!body?.token) return
//     await dispatch(
//       sessionApi.endpoints.auto_login.initiate(body.token),
//     ).unwrap()
//   } catch (error) {
//     if (isFetchBaseQueryError(error)) {
//       if (typeof error.data === 'string') {
//         throw new Error(error.data)
//       }
//     }

//     throw new Error('Unknown error')
//   }
// })

type accountsSliceState =
  | {
      list: string[]
      total: number
      current: string | null
    }
  | {
      list: string[]
      total: number
      current: string | null
    }

const initialState: accountsSliceState = {
  list: [],
  total: 0,
  current: null,
}

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    clearData: state => {
      state.list = []
    },
    setCurrentAccount: (state, { payload }) => {
      state.current = payload
    },
    setTransaction: () => {},
  },
  extraReducers: builder => {
    builder.addMatcher(
      accountsApi.endpoints.accounts.matchFulfilled,
      (state, { payload }) => {
        state.list = payload.list
        state.total = payload.totals
      },
    )
    builder.addMatcher(
      accountsApi.endpoints.account.matchFulfilled,
      (state, { payload }) => {
        state.current = payload
      },
    )
  },
})

export const selectCurrentChart = (state: RootState) => state.session.me

export const selectRate = (state: RootState) => state.currency.rates

export const selectAccountsList = (state: RootState): AccountData[] =>
  state.accounts.list

export const selectAccountsTotal = (state: RootState): number =>
  state.accounts.total

export const selectCurrentAccount = (state: RootState): number =>
  state.accounts.current

export const accountsTotalSelector = createSelector(
  [selectAccountsList, selectRate],
  (list, rates) =>
    list.reduce((acc, item) => item.balance * rates[item.currency], 0),
)

export const selectAccountById = (id: AccountData['id']) =>
  createSelector([selectAccountsList], list =>
    list.find(item => item.id === id),
  )

export const selectAccountDropdownList = createSelector(
  [selectAccountsList],
  list =>
    list.map(item => ({
      label: `#${item.uid} (${priceToView(item.balance, item.currency)})`,
      uid: item.uid,
      value: item.id,
      currency: item.currency,
    })),
)

export const { clearData, setCurrentAccount, setTransaction } =
  accountsSlice.actions
