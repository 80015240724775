import { createSlice, createSelector } from '@reduxjs/toolkit'

import { RootState } from 'app/AppStore'

import { ordersApi } from '../api/ordersApi'
import { IOrdersData } from '../types'
import { calculateMargin } from 'shared/utils/tranding/calculateMargin'

type ordersSliceState = {
  active: IOrdersData[]
  current: IOrdersData
  history: IOrdersData[]
}

const initialState: ordersSliceState = {
  active: [],
  current: {},
  history: [],
}

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    clearData: state => {
      state.active = []
      state.current = {}
      state.history = []
    },
    setOrder: (state, { payload }) => {
      state.active = state.active.map(item =>
        item.id === payload.id ? payload : item,
      )
      state.history = state.history.map(item =>
        item.id === payload.id ? payload : item,
      )
    },
    setNewOrder: (state, { payload }) => {
      state.active = [payload, ...state.active]
    },
    onChangeOrderAction: (state, { payload }) => {
      state.active[payload.index][payload.name] = payload.value
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      ordersApi.endpoints.orders.matchFulfilled,
      (state: ordersSliceState, { payload }) => {
        state.active = payload
      },
    )
    builder.addMatcher(
      ordersApi.endpoints.ordersHistory.matchFulfilled,
      (state: ordersSliceState, { payload }) => {
        state.history = payload
      },
    )
    builder.addMatcher(
      ordersApi.endpoints.createOrder.matchFulfilled,
      (state: ordersSliceState, { payload }) => {
        state.active = [payload, ...state.active]
      },
    )
    builder.addMatcher(
      ordersApi.endpoints.updateOrder.matchFulfilled,
      (state: ordersSliceState, { payload }) => {
        state.history = [payload, ...state.history]
        state.active = state.active.filter(item => item.id !== payload.id)
      },
    )
  },
})

export const selectCurrentChart = (state: RootState) => state.session.me

export const activeOrders = (state: RootState) => state.orders.active

export const historyOrders = (state: RootState) => state.orders.history

export const selectLeverage = (state: RootState) =>
  state?.accounts?.current?.sg_settings

export const selectSymbols = (state: RootState) => state?.symbols?.symbols

export const selectActiveOrders = createSelector(
  [activeOrders],
  orders => orders,
)

export const selectHistoryOrders = createSelector(
  [historyOrders],
  orders => orders,
)

export const selectMargin = createSelector(
  [activeOrders, selectLeverage, selectSymbols],
  calculateMargin,
)

export const { clearData, onChangeOrderAction, setOrder, setNewOrder } =
  ordersSlice.actions
