import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { sessionApi } from '../api/sessionApi'

import { RootState } from 'app/AppStore'

import { isFetchBaseQueryError } from 'shared/api'

import { toast } from 'react-toastify'
import { User } from './types'

type Params = {
  token: string
}

export const autologinThunk = createAsyncThunk<
  void,
  Params,
  { state: RootState }
>('authentication/autologin', async (body: Params, { dispatch }) => {
  try {
    if (!body?.token) return
    await dispatch(
      sessionApi.endpoints.auto_login.initiate(body.token),
    ).unwrap()
  } catch (error) {
    if (isFetchBaseQueryError(error)) {
      if (typeof error.data === 'string') {
        throw new Error(error.data)
      }
    }

    throw new Error('Unknown error')
  }
})

type SessionSliceState =
  | {
      accessToken: string
      isAuthorized: true
      me: User
    }
  | {
      isAuthorized: false
      accessToken?: string
      me: null
    }

const initialState: SessionSliceState = {
  isAuthorized: false,
  me: null,
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    clearSessionData: state => {
      state.accessToken = undefined
      state.isAuthorized = false
    },

    setChatMeId: (state, action) => {
      state.me.chat_id = action.payload
    },

    setImageStatus: (state, { payload }) => {
      state.me.files = state.me.files.map(file =>
        file.id === payload.id ? { ...file, status: payload.status } : file,
      )
    },
    setImageUrl: (state, { payload }) => {
      state.me.files = state.me.files.map(file =>
        file.id === payload.id ? { ...file, src: payload.src } : file,
      )
    },

    setAvatarUrl: (state, { payload }) => {
      state.me.avatar = payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      sessionApi.endpoints.login.matchFulfilled,
      (state: SessionSliceState, { payload, dispatch }) => {
        if (payload.accessToken) {
          state.accessToken = payload.accessToken
          state.isAuthorized = true
        }
      },
    )

    builder.addMatcher(
      sessionApi.endpoints.login.matchRejected,
      (state, { payload }) => {
        toast.error('Authorization failed.', { theme: 'dark' })
      },
    )

    builder.addMatcher(
      sessionApi.endpoints.auto_login.matchFulfilled,
      (state: SessionSliceState, { payload }) => {
        if (payload.accessToken) {
          state.accessToken = payload.accessToken
          state.isAuthorized = true
          window.location.reload()
          toast.done('Authorization success.', { theme: 'dark' })
        }
      },
    )
    builder.addMatcher(
      sessionApi.endpoints.auto_login.matchRejected,
      (state: SessionSliceState, { payload }) => {
        state.accessToken = null
        state.isAuthorized = false
      },
    )
    builder.addMatcher(
      sessionApi.endpoints.changePassword.matchFulfilled,
      (state: SessionSliceState, { payload }) => {
        toast.success(payload.message, { theme: 'dark' })
      },
    )
    builder.addMatcher(
      sessionApi.endpoints.changePassword.matchRejected,
      (state: SessionSliceState, { payload }) => {
        toast.error(payload.message, { theme: 'dark' })
      },
    )

    builder.addMatcher(
      sessionApi.endpoints.logout.matchFulfilled,
      (state: SessionSliceState) => {
        state.accessToken = undefined
        state.isAuthorized = false
      },
    )
    builder.addMatcher(
      sessionApi.endpoints.me.matchFulfilled,
      (state: SessionSliceState, { payload }) => {
        state.me = payload
      },
    )
    builder.addMatcher(sessionApi.endpoints.registration.matchFulfilled, () => {
      toast.success('Registration was successfully. Please login.', {
        theme: 'dark',
      })
    })
    builder.addMatcher(
      sessionApi.endpoints.uploadFile.matchFulfilled,
      (state: SessionSliceState, { payload }) => {
        state.me.files = payload.data.files
      },
    )
  },
})

export const selectIsAuthorized = (state: RootState) =>
  state.session.isAuthorized

export const selectUserId = (state: RootState) => state.session

export const selectMe = (state: RootState) => state.session.me

export const selectCompanyId = (state: RootState) =>
  state?.session?.me?.company_id

export const selectFiles = (state: RootState) => state.session.me.files

export const selectAvatar = (state: RootState) => state.session.me.avatar

export const {
  clearSessionData,
  setChatMeId,
  setImageStatus,
  setImageUrl,
  setAvatarUrl,
} = sessionSlice.actions
