export const ORDER_TYPE_TITLE = {
  1: 'BUY',
  2: 'SELL',
}

export const ORDER_TYPE = {
  BUY: 1,
  SELL: 2,
}

export const ORDER_VOLUME_TYPE = {
  LOTS: 1,
  UNIT: 2,
  CURRENCY: 3,
}

export const ORDER_VOLUME_TITLE = {
  1: 'LOTS',
  2: 'UNIT',
  3: 'CURRENCY',
}
