import React from 'react'
import cn from 'classnames'
import styles from './Switch.module.sass'

export const Switch = ({ className, checked, onChange, disabled }) => {
  return (
    <label className={cn(styles.switch, className)}>
      <input
        className={styles.input}
        type='checkbox'
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className={styles.inner}>
        <span className={styles.box}></span>
      </span>
    </label>
  )
}
