import React, { useState, useMemo } from 'react'
import cn from 'classnames'
import styles from './SelectCrypto.module.sass'
import { Icon } from '../Icon/Icon'
import { useAppSelector } from 'app/AppStore'
import {
  selectQuoteByName,
  selectSymbolByName,
  selectSymbols,
} from 'entities/symbols/model/slice'
import { priceToView } from 'shared/utils/format/currency'
import { useIntl } from 'react-intl'

const sessionChange = (quote, item) => {
  if (!quote) return 0
  const changePercentage =
    ((quote?.volume - item?.previous_close) / item?.previous_close) * 100
  return changePercentage?.toFixed(2)
}

const SymbolItem = ({ goNext, index, x, onClickItem }) => {
  const quote = useAppSelector(selectQuoteByName(x.symbol))
  const symbol = useAppSelector(selectSymbolByName(x.symbol))
  const intl = useIntl()

  const sessionChangeValue = useMemo(
    () => sessionChange(quote, symbol?.quote),
    [quote],
  )

  const onClick = () => {
    if (!!quote?.volume) {
      onClickItem(x, quote)
      goNext()
    }
  }

  const messages = {
    closed: intl.formatMessage({
      id: 'closed',
      defaultMessage: 'closed',
    }),
  }

  return (
    <div className={styles.row} key={index} onClick={onClick}>
      <div className={styles.col}>
        <div className={styles.line}>
          <button className={cn('favorite', styles.favorite)}>
            <Icon name='star-outline' size='16' />
          </button>
          {index + 1}
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.item}>
          <div className={styles.icon}>
            {/* <img src={x.image} alt='Coin' /> */}
            <div
              style={{ zoom: '0.25' }}
              className={`symbol symbol-${x.symbol}`}
            ></div>
          </div>
          <div className={styles.details}>
            <p className='symbol-name'>{x.alias}</p>
            {/* <span className={styles.subtitle}>{x.title}</span>
            <span className={styles.currency}>{x.currency}</span> */}
          </div>
        </div>
      </div>
      {!!quote?.volume ? (
        <div className={styles.col}>{priceToView(quote.volume)}</div>
      ) : (
        <div className={styles.col}>{messages.closed}</div>
      )}

      <div className={styles.col}>
        {sessionChangeValue > 0 && (
          <div className={styles.positive}>{`${sessionChangeValue}%`}</div>
        )}
        {sessionChangeValue < 0 && (
          <div className={styles.negative}>{`${sessionChangeValue}%`}</div>
        )}
      </div>
    </div>
  )
}

export const SelectCrypto = ({ goNext, onClickItem }) => {
  const intl = useIntl()
  const symbols = useAppSelector(selectSymbols)

  const [search, setSearch] = useState('')

  const filteredItems = Object.values(symbols)?.filter(item =>
    !search ? item : item?.alias?.toLowerCase().includes(search.toLowerCase()),
  )

  const messages = {
    search: intl.formatMessage({
      id: 'search',
      defaultMessage: 'Search',
    }),
    name: intl.formatMessage({
      id: 'name',
      defaultMessage: 'Name',
    }),
    price: intl.formatMessage({
      id: 'price',
      defaultMessage: 'Price',
    }),
    changePct: intl.formatMessage({
      id: 'changePct',
      defaultMessage: '24h',
    }),
    select: intl.formatMessage({
      id: 'select',
      defaultMessage: 'Select',
    }),
  }

  return (
    <div className={styles.crypto}>
      <div className={styles.title}>{messages.select}</div>
      <form className={styles.form}>
        <input
          className={styles.input}
          type='text'
          value={search}
          onChange={e => setSearch(e.target.value)}
          name='search'
          placeholder={messages.search}
          required
        />
        <button className={styles.result}>
          <Icon name='search' size='20' />
        </button>
      </form>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className='sorting'>#</div>
          </div>
          <div className={styles.col}>
            <div className='sorting'>{messages.name}</div>
          </div>
          <div className={styles.col}>
            <div className='sorting'>{messages.price}</div>
          </div>
          <div className={styles.col}>{messages.changePct}</div>
        </div>
        {filteredItems?.map((x, index) => (
          <SymbolItem
            x={x}
            goNext={goNext}
            key={index}
            index={index}
            onClickItem={onClickItem}
          />
        ))}
      </div>
    </div>
  )
}
