import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import styles from './Withdraw.module.sass'
import { Icon } from '../Icon/Icon'
import { TextInput } from '../TextInput/TextInput'
import { Checkbox } from '../Checkbox/Checkbox'
import { Dropdown } from '../Dropdown/Dropdown'
import { Successfully } from './Successfully/Successfully'
import { priceToView } from 'shared/utils/format/currency'
import { useIntl } from 'react-intl'
import { useWithdrawalPspQuery } from 'entities/psp/api/withdrawalPspApi'
import { useAppSelector } from 'app/AppStore'
import { selectMe } from 'entities/session/model/slice'
import { selectAccountsList } from 'entities/accounts/model/slice'

const initialState = {
  account_id: '',
  withdrawal_psp: '',
  transaction_mode: 2, // 1 - deposit 2 - withdrawal
  amount: 0,
  type: 1, // 1 - balance, 2 - credit
  transaction_type: 1, // 1-real/2-fake
  currency: 'EUR',
  info: '',
  bank_data: {},
  card_data: {},
}

export const Withdraw = ({ account, accounts, onCreate }) => {
  const intl = useIntl()
  const me = useAppSelector(selectMe)
  const accountsList = useAppSelector(selectAccountsList)
  const { data: withdrawalPspOptions = [] } = useWithdrawalPspQuery({
    brand_id: me.brand_id,
    type: 1,
  })
  const [state, setState] = useState({
    ...initialState,
    currency: account?.currency,
    account_id: account?.id,
  })
  const [save, setSave] = useState(true)
  const [visibleWithdraw, setVisibleWithdraw] = useState(true)
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false)
  const [selectedAcc, setSelectedAcc] = useState({
    currency: accounts?.[0]?.currency,
    account_id: accounts?.[0]?.value,
    label: accounts?.[0].label,
  })

  useEffect(() => {
    if (account) {
      setState(prev => ({
        ...prev,
        currency: account.currency,
        account_id: account.id,
      }))
    }
  }, [account])

  const handleClick = () => {
    if (!account) {
      const data = {
        ...state,
        account_id: selectedAcc.account_id,
        currency: selectedAcc.currency,
      }
      onCreate(data)
    } else {
      onCreate(state)
    }

    setVisibleWithdraw(false)
    setVisibleSuccessfully(true)
  }

  const onChange = e => {
    const { name, value } = e.target
    setState(prev => ({ ...prev, [name]: value }))
  }

  const onChangeAccount = value => {
    const data = accounts.find(item => item.label === value)
    setSelectedAcc(data)
  }

  const onChangeWithdrawalType = value => {
    setState(prev => ({ ...prev, withdrawal_psp: value }))
  }

  const onChangeBankData = e => {
    const { name, value } = e.target
    setState(prev => ({
      ...prev,
      bank_data: {
        ...prev.bank_data,
        [name]: value,
      },
    }))
  }

  const onChangeCardData = e => {
    const { name, value } = e.target
    setState(prev => ({
      ...prev,
      card_data: {
        ...prev.card_data,
        [name]: value,
      },
    }))
  }

  const onChangeCryptoData = e => {
    const { value } = e.target
    setState(prev => ({
      ...prev,
      info: value,
    }))
  }

  const renderWithdrawalDetails = () => {
    const selectedPsp = withdrawalPspOptions.find(
      option => option.id === state.withdrawal_psp,
    )
    if (!selectedPsp) return null

    switch (selectedPsp.psp_type) {
      case 3: // Bank Transfer
        return (
          <>
            <TextInput
              className={styles.field}
              label='Account Number'
              name='accountNumber'
              type='text'
              required
              onChange={onChangeBankData}
              value={state.bank_data.accountNumber || ''}
            />
            <TextInput
              className={styles.field}
              label='Account Holder Name'
              name='accountHolderName'
              type='text'
              required
              onChange={onChangeBankData}
              value={state.bank_data.accountHolderName || ''}
            />
            <TextInput
              className={styles.field}
              label='IBAN'
              name='iban'
              type='text'
              required
              onChange={onChangeBankData}
              value={state.bank_data.iban || ''}
            />
            <TextInput
              className={styles.field}
              label='Bank SWIFT Code'
              name='swiftCode'
              type='text'
              required
              onChange={onChangeBankData}
              value={state.bank_data.swiftCode || ''}
            />
            <TextInput
              className={styles.field}
              label='Recipient Address'
              name='recipientAddress'
              type='text'
              required
              onChange={onChangeBankData}
              value={state.bank_data.recipientAddress || ''}
            />
            <TextInput
              className={styles.field}
              label='Routing Number'
              name='routingNumber'
              type='text'
              required
              onChange={onChangeBankData}
              value={state.bank_data.routingNumber || ''}
            />
            <TextInput
              className={styles.field}
              label='Bank of Beneficiary Name'
              name='beneficiaryBankName'
              type='text'
              required
              onChange={onChangeBankData}
              value={state.bank_data.beneficiaryBankName || ''}
            />
            <TextInput
              className={styles.field}
              label='Bank Address'
              name='bankAddress'
              type='text'
              required
              onChange={onChangeBankData}
              value={state.bank_data.bankAddress || ''}
            />
            <TextInput
              className={styles.field}
              label='Bank Code (BSB)'
              name='bankCode'
              type='text'
              required
              onChange={onChangeBankData}
              value={state.bank_data.bankCode || ''}
            />
            <TextInput
              className={styles.field}
              label='Description'
              name='description'
              type='text'
              required
              onChange={onChangeBankData}
              value={state.bank_data.description || ''}
            />
          </>
        )
      case 2: // Card Transfer
        return (
          <>
            <TextInput
              className={styles.field}
              label='Card Number'
              name='cardNumber'
              type='text'
              required
              onChange={onChangeCardData}
              value={state.card_data.cardNumber || ''}
              maxLength={16}
            />
            <TextInput
              className={styles.field}
              label='Cardholder Name'
              name='cardHolderName'
              type='text'
              required
              onChange={onChangeCardData}
              value={state.card_data.cardHolderName || ''}
            />
          </>
        )
      case 1: // Crypto (USDT TRC-20, Bitcoin, Ethereum)
        return (
          <TextInput
            className={styles.field}
            label={`${selectedPsp.title} Address`}
            name='cryptoAddress'
            type='text'
            required
            onChange={onChangeCryptoData}
            value={state.info}
          />
        )
      default:
        return null
    }
  }

  const messages = {
    search: intl.formatMessage({
      id: 'search',
      defaultMessage: 'Search',
    }),
    account: intl.formatMessage({
      id: 'account',
      defaultMessage: 'Account',
    }),
    price: intl.formatMessage({
      id: 'price',
      defaultMessage: 'Price',
    }),
    withdrawFunds: intl.formatMessage({
      id: 'withdrawFunds',
      defaultMessage: 'Withdraw Funds',
    }),
    select: intl.formatMessage({
      id: 'select',
      defaultMessage: 'Select',
    }),
    total: intl.formatMessage({
      id: 'TOKEN_TOTAL',
      defaultMessage: 'Total',
    }),

    withdrawDetails: intl.formatMessage({
      id: 'withdrawDetails',
      defaultMessage: 'Withdrawal details',
    }),
    saveDetails: intl.formatMessage({
      id: 'saveDetails',
      defaultMessage: 'Save details for next time',
    }),
    amountToWithdraw: intl.formatMessage({
      id: 'amountToWithdraw',
      defaultMessage: 'Amount to withdraw',
    }),
    withdrawLimit: intl.formatMessage({
      id: 'withdrawLimit',
      defaultMessage: '$1,000,000.00 daily withdrawal limit remaining.',
    }),
  }

  const currBalance = account
    ? account?.balance
    : accountsList?.find(acc => acc.id === selectedAcc.account_id).balance

  return (
    <>
      {visibleWithdraw && (
        <div className={styles.withdraw}>
          <div className={cn('h4', styles.title)}>
            <Icon name='arrow-left' size='32' />
            {messages.withdrawFunds}
          </div>
          <Dropdown
            className={styles.dropdown}
            classDropdownBody={styles.dropdownBody}
            label='Withdrawal Type'
            value={
              withdrawalPspOptions.find(
                option => option.id === state.withdrawal_psp,
              )?.title
            }
            setValue={title =>
              onChangeWithdrawalType(
                withdrawalPspOptions.find(option => option.title === title)?.id,
              )
            }
            options={withdrawalPspOptions.map(option => option.title)}
          />
          {renderWithdrawalDetails()}
          <Checkbox
            className={styles.checkbox}
            value={save}
            onChange={() => setSave(!save)}
            content={messages.saveDetails}
          />
          {account ? (
            <div className={styles.wrap}>
              <div className={styles.category}>
                Available <br></br>balance
              </div>
              <div className={styles.details}>
                <div className={styles.currency}>
                  {priceToView(account?.balance, account.currency)}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.field}>
              <Dropdown
                className={styles.dropdown}
                classDropdownBody={styles.dropdownBody}
                label={messages.account}
                value={selectedAcc.label}
                setValue={onChangeAccount}
                options={accounts.map(item => item.label)}
              />
            </div>
          )}

          <div className={styles.box}>
            <TextInput
              className={styles.field}
              label={messages.amountToWithdraw}
              name='amount'
              type='number'
              note={messages.withdrawLimit}
              required
              onChange={e => {
                if (e.target.value > currBalance) return

                onChange(e)
              }}
              value={state.amount}
            />
            <button
              onClick={() =>
                onChange({
                  target: {
                    name: 'amount',
                    value: currBalance,
                  },
                })
              }
              className={cn('button-stroke button-small', styles.button)}
            >
              Max amount
            </button>
          </div>
          <TextInput
            className={styles.field}
            label={messages.total}
            name='total'
            type='number'
            placeholder='0.0'
            required
            value={state.amount}
          />
          <button className={cn('button', styles.button)} onClick={handleClick}>
            {messages.withdrawFunds}
          </button>
        </div>
      )}
      {visibleSuccessfully && (
        <Successfully accountUid={account?.uid} state={state} />
      )}
    </>
  )
}
