import { createSlice, createSelector } from '@reduxjs/toolkit'
import { CurrencyData } from '../api/types'

import { RootState } from 'app/AppStore'

import { currencyApi } from '../api/currencyApi'

import { Convert } from 'easy-currencies'

type currencySliceState =
  | {
      list: CurrencyData[]
      total: number
      rates: { [key: string]: number }
    }
  | {
      list: CurrencyData[]
      total: number
      rates: { [key: string]: number }
    }

const initialState: currencySliceState = {
  list: [],
  total: 0,
  rates: {},
}

export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    clearData: state => {
      state.list = []
    },
    setRates: (state, { payload }) => {
      state.rates = payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      currencyApi.endpoints.currency.matchFulfilled,
      (state, { payload }) => {
        state.list = payload.list
        state.total = payload.total
      },
    )
  },
})

export const selectCerrencyList = (state: RootState): CurrencyData[] =>
  state.currency.list

export const selectRatesList = (state: RootState) => state.currency.rates

export const selectRateByCurrency = currency =>
  createSelector([selectRatesList], list => list?.[currency] || 1)

export const { clearData, setRates } = currencySlice.actions
