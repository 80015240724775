import React from 'react'
import cn from 'classnames'
import styles from './PaymentInfo.module.sass'

import { TextInput, Dropdown } from 'shared/ui'
import { useAppSelector } from 'app/AppStore'
import { selectCerrencyList } from 'entities/currency/model/slice'

const cards = [
  {
    image: './images/content/visa.svg',
    alt: 'Visa',
  },
  {
    image: './images/content/master-card.svg',
    alt: 'Master Card',
  },
]

export const PaymentCard = ({ onSave, onChange, state }) => {
  const currency = useAppSelector(selectCerrencyList)

  const handleCardNumberChange = (e) => {
    const { value } = e.target
    const formattedValue = value.replace(/\s?/g, '').replace(/(\d{4})/g, '$1 ').trim()
    if (formattedValue.length <= 19) {
      onChange({
        target: {
          name: 'card_number',
          value: formattedValue,
        },
      })
    }
  }

  const handleExpirationChange = (e) => {
    const { value } = e.target
    const formattedValue = value
      .replace(/\s?/g, '')
      .replace(/(\d{2})(\d{2})/, '$1 / $2')
      .trim()
    if (formattedValue.length <= 7) {
      onChange({
        target: {
          name: 'expiration',
          value: formattedValue,
        },
      })
    }
  }

  const handleCVCChange = (e) => {
    const { value } = e.target
    if (value.length <= 3) {
      onChange({
        target: {
          name: 'cvc',
          value: value,
        },
      })
    }
  }

  return (
    <div className={styles.item}>
      <div className={styles.line}>
        <div className={styles.subtitle}>Credit Card</div>
        <div className={styles.cards}>
          {cards.map((x, index) => (
            <div className={styles.card} key={index}>
              <img src={x.image} alt={x.alt} />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label='Card Number'
          name='card_number'
          type='tel'
          placeholder='XXXX XXXX XXXX XXXX'
          required
          autoComplete='off'
          value={state.card_number}
          onChange={handleCardNumberChange}
        />
        <TextInput
          className={styles.field}
          label='Card Holder'
          name='card_holder'
          type='text'
          placeholder='TRAN MAU TRI TAM'
          required
          value={state.card_holder}
          onChange={onChange}
        />
        <div style={{ marginBottom: '20px' }} className={styles.row}>
          <TextInput
            className={styles.field}
            label='Expiration Date'
            name='expiration'
            type='tel'
            placeholder='MM / YY'
            required
            value={state.expiration}
            onChange={handleExpirationChange}
          />
          <TextInput
            className={styles.field}
            label='CVC'
            name='cvc'
            type='number'
            placeholder='CVC'
            required
            value={state.cvc}
            onChange={handleCVCChange}
          />
        </div>
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label='Amount'
            name='amount'
            type='number'
            placeholder={state.currency}
            required
            value={state.amount}
            onChange={onChange}
          />
          <Dropdown
            className={styles.dropdown}
            label='Currency'
            value={state.currency}
            setValue={value =>
              onChange({
                target: {
                  name: 'currency',
                  value,
                },
              })
            }
            options={currency.map(item => item.title)}
          />
        </div>
      </div>
      <div className={styles.btns}>
        <button
          disabled={!state?.amount}
          className={cn('button', styles.button)}
          onClick={onSave}
        >
          Continue
        </button>
      </div>
    </div>
  )
}
