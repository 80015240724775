import { baseApi } from 'shared/api'

import { CurrencyData } from './types'
import { mapCurrency } from '../lib/mapCurrency'

export const currencyApi = baseApi.injectEndpoints({
  endpoints: build => ({
    currency: build.query<CurrencyData, string>({
      query: () => ({
        url: '/currencies/cfd/list',
        method: 'GET',
      }),
      transformResponse: mapCurrency,
    }),
    currencyRate: build.query<CurrencyData, string>({
      query: () => ({
        url: '/currencies/cfd/conversions',
        method: 'GET',
      }),
    }),
  }),
})

export const { useCurrencyQuery } = currencyApi
