import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Profile.module.sass'
import { NavLink, useLocation } from 'react-router-dom'
import { Icon } from '../Icon/Icon'
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs'
import { useIntl } from 'react-intl'

export const Profile = ({ title, breadcrumbs, children }) => {
  const { pathname } = useLocation()
  const [visible, setVisible] = useState(false)
  const intl = useIntl()

  // documents: intl.formatMessage({
  //   id: 'document',
  //   defaultMessage: 'Documents',
  // }),

  const navigation = [
    {
      title: intl.formatMessage({
        id: 'personalDetails',
        defaultMessage: 'Profile',
      }),
      icon: 'user',
      url: '/profile-info',
    },

    {
      title: intl.formatMessage({
        id: 'sessionLog',
        defaultMessage: 'Sessions & login history',
      }),
      icon: 'laptop',
      url: '/sessions-and-login-history',
    },

    {
      title: intl.formatMessage({
        id: 'liveChat',
        defaultMessage: 'Support',
      }),
      icon: 'email',
      url: '/support',
    },
    {
      title: intl.formatMessage({
        id: 'changePassword',
        defaultMessage: 'Change Password',
      }),
      icon: 'lock',
      url: '/change-password',
    },
    {
      title: intl.formatMessage({
        id: 'document',
        defaultMessage: 'Documents',
      }),
      icon: 'laptop',
      url: '/documents',
    },
  ]

  const activeLink = navigation.find(x => pathname.includes(x.url))

  return (
    <div className={styles.profile}>
      <div className={styles.head}>
        <div className={cn('container', styles.container)}>
          <h2 className={cn('h2', styles.title)}>{title}</h2>
          <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
        </div>
      </div>
      <div className={styles.body}>
        <div className={cn('container', styles.container)}>
          <div className={styles.sidebar}>
            <div className={cn(styles.dropdown, { [styles.active]: visible })}>
              <div className={styles.top} onClick={() => setVisible(!visible)}>
                <Icon name={activeLink.icon} size='24' />
                {activeLink.title}
              </div>
              <div className={styles.group}>
                <div className={styles.menu}>
                  {navigation.map((item, index) => (
                    <NavLink
                      className={cn(styles.link, {
                        [styles.separator]: item.separator,
                      })}
                      activeClassName={styles.active}
                      key={index}
                      to={item.url}
                      exact
                      onClick={() => setVisible(false)}
                    >
                      <Icon name={item.icon} size='24' />
                      {item.title}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.wrapper}>{children}</div>
        </div>
      </div>
    </div>
  )
}
