export const groupedSymbols = symbols =>
  Object.values(symbols).reduce((acc, symbolObj) => {
    const { symbol, group } = symbolObj

    if (!acc[group]) {
      acc[group] = []
    }
    acc[group].push(symbol)
    return acc
  }, {})

export const accountGroupsTitle = {
  1: 'MAIN',
  2: 'BRONZE',
  3: 'SILVER',
  4: 'GOLD',
  5: 'PLATINUM',
  6: 'VIP',
  7: 'NO',
}

export const accountGroups = {
  MAIN: 1,
  BRONZE: 2,
  SILVER: 3,
  GOLD: 4,
  PLATINUM: 5,
  VIP: 6,
  NO: 7,
}
export const groupsTitle = {
  MAIN: 'MAIN',
  BRONZE: 'BRONZE',
  SILVER: 'SILVER',
  GOLD: 'GOLD',
  PLATINUM: 'PLATINUM',
  VIP: 'VIP',
  CX: 'CX',
}

export const transactionType = {
  1: 'Deposit',
  2: 'Withdraw',
}

export const typeTitle = {
  1: 'BALANCE',
  2: 'CREDIT',
}

export const transactionStatusTitle = {
  1: 'APPROVED',
  2: 'DECLINED',
  3: 'PENDING',
  4: 'IN_PROCESS',
  5: 'REVERTED',
}
