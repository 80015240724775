import { baseApi } from 'shared/api'
import { pspDTO } from '../lib/pspDTO'

export const withdrawalPspApi = baseApi.injectEndpoints({
  endpoints: build => ({
    withdrawalPsp: build.query<any, void>({
      query: arg => ({
        url: `withdrawal-psp/cfd/list?brand_id=${arg.brand_id}&type=${arg.type}`,
      }),

      transformResponse: pspDTO,
    }),
    otherWithdrawalPsp: build.query<any, void>({
      query: arg => ({
        url: `withdrawal-psp/cfd/list?brand_id=${arg.brand_id}&type=${2}`,
      }),

      transformResponse: pspDTO,
    }),
  }),
})

export const { useWithdrawalPspQuery, useOtherWithdrawalPspQuery } = withdrawalPspApi
