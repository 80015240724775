import { AccountData } from '../api/types'

export const mapAccounts = ({
  data,
  code,
  message,
  meta,
  status,
}: {
  data: AccountData[]
  code: number
  message: string
  meta: object
  status: boolean
}): { list: AccountData[]; total: number } => {
  const total = data.reduce((acc, item) => (acc += item.balance * 1), 0)

  return { list: data, total }
}
