import { baseApi } from 'shared/api'
import { mapAccounts } from '../lib/mapAccounts'
import { mapTransactions } from '../lib/mapTransactions'
import { TransactionData } from './types'

export const accountsApi = baseApi.injectEndpoints({
  endpoints: build => ({
    accounts: build.query<any, void>({
      query: () => ({
        url: `accounts/cfd/list-items`,
      }),
      transformResponse: mapAccounts,
    }),
    account: build.query<any, void>({
      query: accId => ({
        url: `accounts/cfd/get?id=${accId}`,
      }),
      transformResponse: res => res.data,
    }),

    accountTransaction: build.query<TransactionData[], string>({
      query: accId => ({
        url: `/cfd/transactions/list?account_id=${accId}`,
      }),
      transformResponse: mapTransactions,
    }),

    createTransaction: build.mutation<TransactionData[], string>({
      query: body => ({
        url: `/cfd/transactions/create`,
        method: 'POST',
        body,
      }),
      transformResponse: mapTransactions,
    }),

    transfer: build.mutation<TransactionData[], string>({
      query: body => ({
        url: `/accounts/cfd/transfer`,
        method: 'POST',
        body,
      }),
      transformResponse: mapTransactions,
    }),

    getTotalDeposits: build.query<any, { leadId: string }>({
      query: ({ leadId }) => ({
        url: `account/dashboard/total-deposits`,
        params: {
          lead_id: leadId,
        },
      }),
      transformResponse: (res: { totalAmountConverted: number }) =>
        res.totalAmountConverted,
    }),

    getTotalBalance: build.query<any, { leadId: string }>({
      query: ({ leadId }) => ({
        url: `account/dashboard/total-balance`,
        params: {
          lead_id: leadId,
        },
      }),
      transformResponse: (res: { totalBalance: number }) => res.totalBalance,
    }),

    getProfitableOrdersCount: build.query<any, { leadId: string }>({
      query: ({ leadId }) => ({
        url: `account/dashboard/profitable-orders-count`,
        params: {
          lead_id: leadId,
        },
      }),
      transformResponse: res => res.profitableOrdersCount,
    }),

    getTotalPnL: build.query<any, { leadId: string }>({
      query: ({ leadId }) => ({
        url: `account/dashboard/total-pnl`,
        params: {
          lead_id: leadId,
        },
      }),
      transformResponse: res => res.totalPnL,
    }),

    getOrderStatusPercentages: build.query<any, { leadId: string }>({
      query: ({ leadId }) => ({
        url: `account/dashboard/order-status-percentages`,
        params: {
          lead_id: leadId,
        },
      }),
      transformResponse: res => ({
        ongoingOrdersPercentage: res.ongoingOrdersPercentage,
        closedWithProfitOrdersPercentage: res.closedWithProfitOrdersPercentage,
        closedWithLossOrdersPercentage: res.closedWithLossOrdersPercentage,
      }),
    }),
  }),
})

export const {
  useAccountsQuery,
  useAccountQuery,
  useAccountTransactionQuery,
  useCreateTransactionMutation,
  useTransferMutation,
  useGetTotalDepositsQuery,
  useGetProfitableOrdersCountQuery,
  useGetTotalPnLQuery,
  useGetOrderStatusPercentagesQuery,
  useGetTotalBalanceQuery,
} = accountsApi
