import React from 'react'
import { IntlProvider } from 'react-intl'

import { messages } from 'shared/i18n/messages'
import { useAppSelector } from 'app/AppStore'
import { selectLanguage } from 'entities/settings/model/slice'

export const Localization = ({ children }) => {
  const language = useAppSelector(selectLanguage)

  const locale = language.toLowerCase() || 'en'

  return (
    <IntlProvider
      messages={messages[locale]}
      locale={locale}
      defaultLocale={locale}
    >
      {children}
    </IntlProvider>
  )
}
