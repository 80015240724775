import { baseApi, SESSION_TAG, WISHLIST_TAG } from 'shared/api'
import { mapSession } from '../lib/mapSession'
import { mapUser } from '../lib/mapUser'
import { mapHistoryLog } from '../lib/mapHistoryLog'
import { type User, type Session } from '../model/types'
import { type UserDto, type RequestLoginBody, type SessionDto } from './types'

export const sessionApi = baseApi.injectEndpoints({
  endpoints: build => ({
    login: build.mutation<Session, RequestLoginBody>({
      query: body => ({
        url: `cfd/login`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [SESSION_TAG, WISHLIST_TAG],
      transformResponse: (response: SessionDto) => mapSession(response),
    }),
    registration: build.mutation<Session, RequestLoginBody>({
      query: body => ({
        url: `accounts/cfd/create`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: SessionDto) => mapSession(response),
    }),
    me: build.query<User, void>({
      query: () => ({
        url: `cfd/me`,
      }),
      providesTags: [SESSION_TAG],
      transformResponse: (response: UserDto) => mapUser(response.data),
    }),
    logout: build.query<User, void>({
      query: () => ({
        url: `cfd/me/logout`,
        method: 'POST',
      }),
    }),
    auto_login: build.mutation<Session, string>({
      query: hash => ({
        url: `cfd/secured-auto-login?token=${hash}`,
        method: 'GET',
      }),
      transformResponse: (response: SessionDto) => mapSession(response),
    }),
    logHistory: build.query<Session, string>({
      query: () => ({
        url: 'cfd/me/get-logs',
        method: 'GET',
      }),
      transformResponse: (response: SessionDto) => mapHistoryLog(response),
    }),

    changePassword: build.mutation<Session, string>({
      query: body => ({
        url: 'cfd/me/change-password',
        method: 'POST',
        body,
      }),
    }),
    uploadFile: build.mutation<Session, string>({
      query: body => ({
        url: 'cfd/documents',
        method: 'PATCH',
        body,
      }),
    }),
    uploadAvatar: build.mutation<Session, string>({
      query: body => ({
        url: 'cfd/avatar',
        method: 'PATCH',
        body,
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useMeQuery,
  useLogHistoryQuery,
  useChangePasswordMutation,
  useRegistrationMutation,
  useUploadFileMutation,
  useUploadAvatarMutation,
} = sessionApi
