import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import styles from './Autotrader.module.sass'
import { Switch, Dropdown } from 'shared/ui'
import {
  useGetAutotraderQuery,
  useCreateAutotraderMutation,
  useUpdateAutotraderMutation,
} from 'entities/autotrader/api/autotraderApi'
import { useAppSelector } from 'app/AppStore'
import { selectMe } from 'entities/session/model/slice'

const initialState = {
  id: null,
  status: 2, // 1-active, 2-inactive
  risk_level: 1, // 1- low, 2-medium, 3-high
}

const optionsObj = {
  1: 'Low',
  2: 'Medium',
  3: 'High',
}

const trades = {
  1: '1-3',
  2: '3-4',
  3: '5+',
}

export const Autotrader = ({ onCancel, visible }) => {
  const { id: leadId } = useAppSelector(selectMe)

  const {
    data: autotraderData,
    refetch,
    isLoading,
  } = useGetAutotraderQuery(leadId, {
    skip: !leadId,
  })
  const [createAutotrader] = useCreateAutotraderMutation()
  const [updateAutotrader] = useUpdateAutotraderMutation()

  const [state, setState] = useState(initialState)

  useEffect(() => {
    if (autotraderData && autotraderData.data) {
      const { status, risk_level, id } = autotraderData.data
      setState({
        status,
        risk_level,
        id,
      })
    } else {
      setState(initialState)
    }
  }, [autotraderData])

  useEffect(() => {
    if (visible && leadId) {
      refetch()
    }
  }, [visible, leadId, refetch])

  const handleUpdate = async (name, value) => {
    try {
      setState(prev => ({
        ...prev,
        [name]: value,
      }))
      await updateAutotrader({ leadId, data: { [name]: value } })
    } catch (error) {
      console.error(error)
    }
  }

  const handleCreate = async () => {
    try {
      await createAutotrader({ lead_id: leadId, ...state })
      refetch()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className={styles.autotrader}>
      <div className={cn('h4', styles.title)}>Auto Trader</div>
      <div className={styles.field}>
        <span>Auto Trader</span>
        <Switch
          disabled={!state?.id}
          checked={state.status === 1}
          onChange={() => handleUpdate('status', state.status === 1 ? 2 : 1)}
        />
      </div>
      <>
        {' '}
        <div className={styles.field}>
          <span>Risk Level:</span>
          <div className={styles.radioGroup}>
            <Dropdown
              options={Object.values(optionsObj)}
              setValue={(value, index) => handleUpdate('risk_level', index + 1)}
              value={optionsObj[state.risk_level]}
              classDropdownBody={styles.dropdownList}
            />
          </div>
        </div>
        <div className={styles.field}>
          <span>Trades per day:</span>
          <div> {trades[state.risk_level]}</div>
        </div>
        <p style={{ fontSize: '11px', marginTop: '20px' }}>
          *Trading CFDs and other leveraged products can lead to losses. Before
          trading, clients should read the relevant risk statements on our Risk
          Disclosure page. Automated trading does not guarantee results. The
          company accepts no responsibility for the loss of funds in automatic
          trading. Please make sure that you fully understand the risks and take
          measures to manage risks.
        </p>
        {!state?.id && !isLoading && (
          <button
            onClick={handleCreate}
            className={cn('button-green', styles.button)}
          >
            Confirm and Save
          </button>
        )}
      </>
    </div>
  )
}
