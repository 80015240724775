import { combineReducers } from '@reduxjs/toolkit'
import { baseApi } from 'shared/api'
import { themeSlice } from 'entities/theme'
import { sessionSlice } from 'entities/session'
import { settingsSlice } from 'entities/settings/model/slice'
import { supportSlice } from 'entities/support/model/slice'
import { chartsSlice } from 'entities/charts/model/slice'
import { symbolsSlice } from 'entities/symbols/model/slice'
import { accountsSlice } from 'entities/accounts/model/slice'
import { currencySlice } from 'entities/currency/model/slice'
import { ordersSlice } from 'entities/orders/model/slice'
import { trandingSlice } from 'entities/tranding/model/slice'
import { pspSlice } from 'entities/psp/model/slice'

export const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  [themeSlice.name]: themeSlice.reducer,
  [sessionSlice.name]: sessionSlice.reducer,
  [settingsSlice.name]: settingsSlice.reducer,
  [supportSlice.name]: supportSlice.reducer,
  [chartsSlice.name]: chartsSlice.reducer,
  [symbolsSlice.name]: symbolsSlice.reducer,
  [accountsSlice.name]: accountsSlice.reducer,
  [currencySlice.name]: currencySlice.reducer,
  [ordersSlice.name]: ordersSlice.reducer,
  [trandingSlice.name]: trandingSlice.reducer,
  [pspSlice.name]: pspSlice.reducer,
})
