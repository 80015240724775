import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { supportApi } from '../api/supportApi'
import { RootState } from 'app/AppStore'

type SupportState = {
  list: []
  status: 'idle' | 'error' | 'success'
  count_unread: number
  chat_id: null | string
}

const initialState: SupportState = {
  list: [],
  count_unread: 0,
  status: 'idle',
  chat_id: '',
}

export const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<[]>) => {
      state.list = action.payload
    },
    setMessage: (state, action: PayloadAction<object>) => {
      state.list = [action.payload, ...state.list]
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      supportApi.endpoints.sendMessage.matchFulfilled,
      (state, { payload }) => {
        state.list = [payload.data, ...state.list]
      },
    )
    builder.addMatcher(
      supportApi.endpoints.createChat.matchFulfilled,
      (state, { payload }) => {
        state.chat_id = payload.data.id
      },
    )
    builder.addMatcher(
      supportApi.endpoints.messages.matchFulfilled,
      (state, { payload }) => {
        state.list = payload.data
      },
    )
  },
})

export const selectMessages = (state: RootState) => {
  const reversedList = state.support.list.slice().reverse()
  return reversedList
}

export const selectChatId = (state: RootState) => state.session?.me?.chat_id

export const { setList, setMessage } = supportSlice.actions
