import { createAsyncThunk } from '@reduxjs/toolkit'

import { RootState } from 'app/AppStore'

import { clearSessionData } from 'entities/session'

import { sessionApi } from 'entities/session'

export const logoutThunk = createAsyncThunk<void, void, { state: RootState }>(
  'authentication/logout',
  async (_: unknown, { dispatch }) => {
    dispatch(sessionApi.endpoints.logout.initiate())
    dispatch(clearSessionData())
  },
)
