import { toast } from 'react-toastify'

import notificationSound from '../../audio/notification.mp3'

type NotifyType = 'message' | 'order' | 'deposit'

const renderIconByType = (type: NotifyType) => {
  switch (type) {
    case 'message':
      return 'Message'
    case 'order':
      return 'Order'
    case 'deposit':
      return 'Deposit'
    default:
      return null
  }
}

const playIncomeMessage = () => {
  const audio = new Audio(notificationSound)
  audio.play()
}

export const customNotify = (
  message: string,
  type: 'message' | 'order' | 'deposit',
) => {
  const icon = renderIconByType(type)

  playIncomeMessage()

  return toast.success(
    <div>
      {icon} {message}
    </div>,
    { theme: 'dark' },
  )
}
