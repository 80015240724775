export const groupByGroup = objects => {
  const grouped = {}
  for (const key in objects) {
    const obj = objects[key]
    const group = obj.type

    if (!grouped[group] && group) {
      grouped[group] = {}
    }
    if (group) {
      grouped[group][key] = obj
    }
  }
  return Object.entries(grouped).map(([label, value]) => ({ label, value }))
}

export const transcformArrayToObject = dataArray => {
  return dataArray.reduce((acc, curr) => {
    acc[curr.t] = {
      volume: curr.p,
      changePct: curr.v,
      symbol: curr.t,
      active: true,
      ...curr,
    }
    return acc
  }, {})
}
