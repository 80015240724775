import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Deposit.module.sass'
import { Icon } from '../Icon/Icon'
import { useAppSelector } from 'app/AppStore'
import { selectPspList } from 'entities/psp/model/slice'
import { useOtherPspQuery } from 'entities/psp/api/pspApi'
import { selectMe } from 'entities/session/model/slice'
import styled from 'styled-components'
import { priceToView } from 'shared/utils/format/currency'
import { PaymentCard } from 'features/buy/ui/PaymentInfo/PaymentCard'
import { useCreateTransactionMutation } from 'entities/accounts/api/accountsApi'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import QRCode from 'qrcode.react'

const OtherMethods = ({
  items,
  handleClickBack,
  handleClickOtherItem,
  account,
}) => {
  const intl = useIntl()

  return (
    <>
      <div className={cn('h4', styles.title)}>
        <button className={styles.back} onClick={() => handleClickBack()}>
          <Icon name='arrow-left' size='32' />
        </button>
        {intl.formatMessage({
          id: 'deposit',
          defaultMessage: 'Deposit',
        })}{' '}
      </div>
      <div className={styles.wrap}>
        <div className={styles.category}>
          {intl.formatMessage({
            id: 'totalBalance',
            defaultMessage: 'Total Balance',
          })}
        </div>
        <div className={styles.details}>
          <div className={styles.currency}>
            {priceToView(account.balance, account.currency)}
          </div>
        </div>
      </div>
      <OtherWrapper>
        {items.map((item, idx) => (
          <OtherItem key={idx} onClick={() => handleClickOtherItem(item)}>
            <img src={item.logo} alt={item.title} />
          </OtherItem>
        ))}
      </OtherWrapper>
    </>
  )
}

export const Deposit = ({
  account,
  goNext = () => null,
  goBack = () => null,
}) => {
  const intl = useIntl()
  const psp = useAppSelector(selectPspList)
  const me = useAppSelector(selectMe)
  const [createBalance] = useCreateTransactionMutation()
  const { data } = useOtherPspQuery({ brand_id: me.brand_id })

  const [activeIndex, setActiveIndex] = useState(0)
  const [visibleBody, setVisibleBody] = useState(false)
  const [visibleHead, setVisibleHead] = useState(true)
  const [activeTitle, setActiveTitle] = useState('')

  const [state, setState] = useState({
    card_number: '',
    card_holder: '',
    expiration: '',
    cvc: '',
    amount: 0,
    currency: 'USD',
  })

  const onChange = e => {
    setState(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleClick = (value, item) => {
    setActiveIndex(value)
    setActiveTitle(item)
    setVisibleBody(true)
    setVisibleHead(false)
    goNext()
  }

  const handleClickBack = () => {
    setVisibleBody(false)
    setVisibleHead(true)
    goBack()
  }

  const handleCreateBalance = async () => {
    const data = {
      account_id: account.id,
      transaction_mode: 1, // 1 - deposit 2 - withdrawal
      amount: state.amount,
      type: 1, // 1 - balance, 2 - credit
      transaction_type: 1, // 1-real/2-fake
      currency: state.currency,

      card_data: state,
    }
    try {
      await createBalance(data).unwrap()
      handleClickBack()
      toast.success('You create deposit successfully!', { theme: 'dark' })
    } catch (error) {
      console.error(error)
    }
  }

  const handleClickOtherItem = item => {
    window.open(item.link, '_blank')
  }

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success('Copied to clipboard!', { theme: 'dark' });
      })
      .catch(error => {
        console.error('Failed to copy: ', error);
      });
  };

  const items = [
    {
      title: 'Crypto',
      content: [
        {
          title: `on ${activeTitle?.title}`,
          note: 'deposits are available after 5-10 min.',
          color: '#9757D7',
          qrCode: '/images/content/qr-code-1.jpg',
        },
      ],
    },
    {
      title: 'USDT ERC20 Wallet',
      content: [
        {
          title: 'USDT ERC20 Wallet',
          note: 'deposits are available after 5-10 min.',
          color: '#3772FF',
          qrCode: '/images/content/qr-code-2.jpg',
        },
      ],
    },
    {
      title: 'Credit/Debit Card cc-ext',
      content: [
        {
          title: 'Credit/Debit Card cc-ext',
          note: 'deposits are available after 5-10 min.',
          color: '#3772FF',
          qrCode: '/images/content/qr-code-2.jpg',
        },
      ],
    },
    {
      title: 'Other',
      content: [
        {
          title: 'Other',
          note: 'USDTTRON deposits are available after 0 network confirmations.',
          color: '#3772FF',
          qrCode: '/images/content/qr-code-2.jpg',
        },
      ],
    },
  ]

  return (
    <div className={styles.deposit}>
      {visibleHead && (
        <div className={styles.head}>
          <div className={styles.icon}>
            <Icon name='wallet' size='24' />
          </div>
          <div className={styles.info}>
            {intl.formatMessage({
              id: 'selectMethods',
              defaultMessage: 'Select methods',
            })}
          </div>
          <div className={styles.btns}>
            {[...psp, { title: 'Other', type: 3 }]?.map((x, index) => (
              <button
                className={cn('button', styles.button)}
                key={index}
                onClick={() => handleClick(index, x)}
              >
                {x.title}
              </button>
            ))}
          </div>
        </div>
      )}
      {visibleBody && (
        <div className={styles.body}>
          {activeTitle?.title === 'Other' ? (
            <OtherMethods
              items={data}
              handleClickBack={handleClickBack}
              handleClickOtherItem={handleClickOtherItem}
              account={account}
            />
          ) : (
            items[activeIndex].content.map((x, index) => (
              <div className={styles.item} key={index}>
                <div className={cn('h4', styles.title)}>
                  <button
                    className={styles.back}
                    onClick={() => handleClickBack()}
                  >
                    <Icon name='arrow-left' size='32' />
                  </button>
                  {intl.formatMessage({
                    id: 'deposit',
                    defaultMessage: 'Deposit',
                  })}{' '}
                </div>
                <div className={styles.subtitle}>{x.title}</div>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <Icon name='wallet' size='24' />
                </div>
                <div className={styles.wrap}>
                  <div className={styles.category}>
                    {intl.formatMessage({
                      id: 'totalBalance',
                      defaultMessage: 'Total Balance',
                    })}
                  </div>
                  <div className={styles.details}>
                    <div className={styles.currency}>
                      {priceToView(account.balance, account.currency)}
                    </div>
                  </div>
                </div>
                {activeTitle?.psp_type === 2 ? (
                  <PaymentCard
                    account={account}
                    onChange={onChange}
                    state={state}
                    onSave={handleCreateBalance}
                  />
                ) : (
                  <>
                    <div
                      className={styles.preview}
                      onClick={() => copyToClipboard(activeTitle?.wallet)}
                    >
                      <QRCode value={activeTitle?.wallet} size={128} />
                    </div>
                    <div className={styles.code}>
                      <div className={styles.number}>
                        {activeTitle?.wallet || '-'}
                      </div>
                      <button
                        onClick={() => copyToClipboard(activeTitle?.wallet)}
                        className={styles.copy}
                      >
                        <Icon name='copy' size='24' />
                      </button>
                    </div>
                    <div className={styles.note}>{x.note}</div>
                  </>
                )}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  )
}

const OtherWrapper = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 20px;
`

const OtherItem = styled.li`
  cursor: pointer;
  background-color: #353945;
  border-radius: 10px;
  padding: 10px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 100%;
    max-width: 100px;
    object-fit: scale-down;
  }
`
